import React from 'react';

const CV: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Enes Can Guven</h1>
      
      <div className="mb-6">
        <div className="flex flex-wrap gap-4">
          <a href="tel:+905367353491" className="text-blue-600 dark:text-blue-400 hover:underline">+90 536 735 3491</a>
          <a href="mailto:enescanguven@gmail.com" className="text-blue-600 dark:text-blue-400 hover:underline">enescanguven@gmail.com</a>
          <a href="https://linkedin.com/in/guvenenes" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">LinkedIn</a>
          <a href="https://github.com/enescanguven" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">GitHub</a>
          <a href="https://enescanguven.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">Website</a>
        </div>
      </div>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Education</h2>
        <div className="mb-4">
          <h3 className="text-xl font-medium">Istanbul Technical University (2019 – 2023)</h3>
          <p>BSc. Control and Automation Engineering</p>
          <p>Istanbul, Turkey</p>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-medium">Yasar Acar High School of Science (2014 – 2018)</h3>
          <p>Math and Science Division</p>
          <p>Istanbul, Turkey</p>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Experience</h2>
        
        <div className="mb-6">
          <h3 className="text-xl font-medium">Trendyol Group (2023 – present)</h3>
          <p className="font-medium">Associate Software Engineer</p>
          <p>Istanbul, Turkey</p>
          <ul className="list-disc ml-6 mt-2">
            <li>Full stack developer</li>
            <li>CyberSecurity Research and Development Team Member</li>
            <li>Technologies: Kubernetes, Docker, FastAPI, Flask, PostgreSQL, RabbitMQ, Redis, MongoDB, Linux, Elasticsearch, React, Git</li>
          </ul>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-medium">Artificial Intelligence and Intelligent Systems (AI2S) Laboratory (2020 – 2023)</h3>
          <p className="font-medium">Intern Researcher (Funded by TUBITAK)</p>
          <p>Istanbul Technical University, Istanbul, Turkey</p>
          <ul className="list-disc ml-6 mt-2">
            <li>Research Areas: Artificial Intelligence, Neural Networks, Machine Learning, Reinforcement Learning</li>
            <li>Publications: "Recognizing and Tracking Person of Interest:- RAST 2023", "A Real-Time Efficient Deep Learning based Method for Drones"</li>
            <li><a href="https://www.youtube.com/watch?v=i7bYXnRy8Vc" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">Demo Video</a></li>
            <li><a href="https://ieeexplore.ieee.org/abstract/document/10197971" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">Paper Link</a></li>
          </ul>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-medium">CRYPTTECH – Cyber Security Artificial Intelligence (April 2022 – November 2022)</h3>
          <p className="font-medium">Software Developer</p>
          <p>Istanbul, Turkey</p>
          <ul className="list-disc ml-6 mt-2">
            <li>Full stack developer</li>
            <li>Development and deployment of machine learning-based anomaly detection algorithms for intrusion detection</li>
          </ul>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Technical Skills</h2>
        <ul className="list-disc ml-6">
          <li><span className="font-medium">Languages:</span> Python, JavaScript, Typescript, C/C++, MATLAB/Simulink</li>
          <li><span className="font-medium">Technologies:</span> Kubernetes, Docker, FastAPI, Flask, PostgreSQL, RabbitMQ, Redis, MongoDB, Linux, Elasticsearch, React, Git</li>
          <li><span className="font-medium">Areas of Expertise:</span> Deep Learning, Machine Learning, Raspberry Pi, Reinforcement Learning</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Projects</h2>
        
        <div className="mb-4">
          <h3 className="text-xl font-medium">Vunga</h3>
          <p>Agile Video Processing Platform</p>
          <p>An open-source AIaaS platform for advanced DL models on lightweight devices with fast data communication over web-socket.</p>
          <a href="https://github.com/enescanguven/vunga" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">GitHub Repository</a>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-medium">AWS Flashcards</h3>
          <p>An open-source project for learning AWS services with flashcards.</p>
          <a href="https://aws-flashcards.enescanguven.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">Project Website</a>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-medium">Recognizing and Tracking Person of Interest With Drones</h3>
          <p>A real-time efficient deep learning-based method for drones (Awarded by MATLAB)</p>
          <p>Technologies: ROS2, YOLO, OpenCV, Python, C++, MATLAB, Simulink, DJI Tello</p>
          <a href="https://github.com/batuhanaavci/Recognizing-and-Tracking-Person-of-Interest" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:underline">GitHub Repository</a>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Additional Information</h2>
        <ul className="list-disc ml-6">
          <li>Amateur radio operator with call sign TA1LCG</li>
          <li>Valid passport with 10-year USA visa</li>
          <li>Valid driving license</li>
          <li>Military service completed in 2024 at Amasya 15th Infantry Brigade</li>
        </ul>
      </section>
    </div>
  );
};

export default CV; 