import {useState} from 'react'
import post_datas from './posts.json'
import Post from '../components/Post'

const Posts = () => {

    const [posts] = useState(post_datas)



    return (

        <div>

            <div >
                {posts.map((post, index) => {
                    return <Post key={index} data={post} />
                })}
            </div>

        </div>
    )
}

export default Posts