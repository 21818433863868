import React, { useState } from 'react'

interface PostProps {
    data: {
        title: string,
        description: string,
        date: string,
        read: string,
        img: string,
        lang: string,
        url: string
    }
}

const Post = ({ data }: PostProps) => {
    const [posts, setPosts] = useState(data)

    const handleClick = () => {
        window.open(data.url)
    }

    return (
        <div className="w-full">
            <div className="flex flex-col md:flex-row md:h-40 gap-4 p-2">
                <div className='w-full md:w-40 h-48 md:h-auto'>
                    <img src={data.img} alt="post" className='w-full h-full object-cover rounded-lg' />
                </div>

                <div className="flex-1 button-shadow rounded-lg flex flex-col justify-between p-4 duration-300 ease-in-out cursor-pointer"
                    onClick={handleClick}>
                    <div>
                        <p className="text-xl md:text-2xl font-bold no-underline hover:underline mb-2">{data.title}</p>
                        <p className="text-gray-500 text-sm md:text-base">{data.description}</p>
                    </div>

                    <div className="mt-4 flex flex-wrap gap-4 text-gray-500 text-sm">
                        <div className='flex items-center space-x-2'>
                            <p>🗓️</p>
                            <p>April 10, 2024</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <p>📖</p>
                            <p>5 min read</p>
                        </div>
                        <div className='flex items-center space-x-2'>
                            <p>{data.lang === 'tr' ? '🇹🇷' : '🇬🇧'}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='border-gray-500 my-4' />
        </div>
    )
}

export default Post