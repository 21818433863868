import React, { Suspense, useCallback, useState, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF, Environment, Stage } from '@react-three/drei';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

// Extend THREE.BufferGeometry to include computeVertexNormals
interface PLYGeometry extends THREE.BufferGeometry {
  computeVertexNormals(): void;
}

// Constants
const supportedFormats = ['.glb', '.gltf', '.ply', '.obj'];

// Default Box component when no model is loaded
function Box() {
  return (
    <mesh rotation={[0, 0, 0]} castShadow receiveShadow>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={new THREE.Color('orange')} />
    </mesh>
  );
}

// PLY Model component
function PLYModel({ url }: { url: string }) {
  const [geometry, setGeometry] = useState<THREE.BufferGeometry | null>(null);

  useEffect(() => {
    const loader = new PLYLoader();

    const loadModel = async () => {
      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const geometry = loader.parse(arrayBuffer);
        geometry.computeVertexNormals();
        geometry.center(); // Center the geometry
        geometry.computeBoundingBox();
        setGeometry(geometry);
      } catch (error) {
        console.error('Error loading PLY file:', error);
      }
    };

    loadModel();

    return () => {
      if (geometry) {
        geometry.dispose();
      }
    };
  }, [url]);

  if (!geometry) return null;

  // Calculate appropriate scale based on bounding box
  const boundingBox = geometry.boundingBox;
  const size = new THREE.Vector3();
  boundingBox?.getSize(size);
  const maxDim = Math.max(size.x, size.y, size.z);
  const scale = 2 / maxDim; // Scale to fit in a 2 unit box

  return (
    <mesh geometry={geometry} scale={scale} castShadow receiveShadow>
      <meshStandardMaterial 
        color="#44a88d" 
        side={THREE.DoubleSide}
        roughness={0.3}
        metalness={0.2}
        emissive="#0d3d30"
        emissiveIntensity={0.2}
      />
    </mesh>
  );
}

// OBJ Model component
function OBJModel({ url }: { url: string }) {
  const [model, setModel] = useState<THREE.Group | null>(null);
  const [loadError, setLoadError] = useState<string | null>(null);
  const [mtlUrl, setMtlUrl] = useState<string | null>(null);

  useEffect(() => {
    const objLoader = new OBJLoader();
    const mtlLoader = new MTLLoader();
    
    const loadModel = async () => {
      try {
        console.log('Starting to load OBJ file from:', url);
        
        // Try to load MTL file if it exists (replace .obj with .mtl)
        const possibleMtlUrl = url.replace('.obj', '.mtl');
        try {
          const mtlResponse = await fetch(possibleMtlUrl);
          if (mtlResponse.ok) {
            const mtlText = await mtlResponse.text();
            // Get the base URL for textures
            const basePath = url.substring(0, url.lastIndexOf('/') + 1);
            
            // Create materials with the correct texture paths
            const materials = mtlLoader.parse(mtlText, basePath);
            materials.preload();
            
            // Set the materials on the OBJ loader
            objLoader.setMaterials(materials);
            setMtlUrl(possibleMtlUrl);
            console.log('Successfully loaded MTL materials');
          }
        } catch (mtlError) {
          console.log('No MTL file found or error loading MTL:', mtlError);
        }

        // Load OBJ file
        const objResponse = await fetch(url);
        if (!objResponse.ok) {
          throw new Error(`HTTP error! status: ${objResponse.status}`);
        }
        
        const objText = await objResponse.text();
        const object = objLoader.parse(objText);
        console.log('Successfully parsed OBJ file');

        // Center and scale the model
        const box = new THREE.Box3().setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);
        const scale = 2 / maxDim;
        
        object.position.sub(center);
        object.scale.multiplyScalar(scale);

        // Add shadows and improve materials only if no MTL file was loaded
        object.traverse((child: THREE.Object3D) => {
          if (child instanceof THREE.Mesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            
            // Ensure geometry has normals
            if (!child.geometry.attributes.normal) {
              child.geometry.computeVertexNormals();
            }

            // Only create new material if no material exists
            if (!child.material) {
              const newMaterial = new THREE.MeshStandardMaterial({
                color: '#44a88d',
                roughness: 0.3,
                metalness: 0.2,
                side: THREE.DoubleSide,
                flatShading: false,
                emissive: '#0d3d30',
                emissiveIntensity: 0.2
              });
              child.material = newMaterial;
            } else {
              // If material exists (from MTL), ensure it's properly configured
              if (Array.isArray(child.material)) {
                child.material.forEach(mat => {
                  if (mat instanceof THREE.Material) {
                    mat.side = THREE.DoubleSide;
                    mat.needsUpdate = true;
                  }
                });
              } else if (child.material instanceof THREE.Material) {
                child.material.side = THREE.DoubleSide;
                child.material.needsUpdate = true;
              }
            }
          }
        });

        console.log('Model processed and ready to display');
        setModel(object);
        setLoadError(null);
      } catch (error) {
        console.error('Error loading OBJ file:', error);
        setLoadError(error instanceof Error ? error.message : 'Unknown error loading OBJ file');
      }
    };

    loadModel();

    return () => {
      if (model) {
        model.traverse((child: THREE.Object3D) => {
          if (child instanceof THREE.Mesh) {
            child.geometry.dispose();
            if (Array.isArray(child.material)) {
              child.material.forEach(mat => {
                if (mat.map) mat.map.dispose();
                mat.dispose();
              });
            } else if (child.material) {
              if (child.material.map) child.material.map.dispose();
              child.material.dispose();
            }
          }
        });
      }
      // Clean up MTL URL
      if (mtlUrl) {
        URL.revokeObjectURL(mtlUrl);
      }
    };
  }, [url]);

  if (loadError) {
    console.error('OBJ loading error:', loadError);
    return null;
  }

  if (!model) {
    return null;
  }

  // Wrap the model in a group to ensure proper rendering with enhanced lighting
  return (
    <group>
      <primitive object={model} />
      <ambientLight intensity={0.7} />
      <pointLight position={[10, 10, 10]} intensity={1.5} castShadow />
      <pointLight position={[-10, -10, -10]} intensity={0.5} />
    </group>
  );
}

// GLTF Model component
function GLTFModel({ url }: { url: string }) {
  const { scene } = useGLTF(url);
  
  useEffect(() => {
    scene.traverse((child: THREE.Object3D) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    // Center and scale the model
    const box = new THREE.Box3().setFromObject(scene);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const scale = 2 / maxDim;
    
    scene.position.sub(center);
    scene.scale.multiplyScalar(scale);
  }, [scene]);

  return <primitive object={scene} />;
}

// Model component that handles different file formats
function Model({ url, fileType }: { url: string; fileType: string }) {
  return (
    <Stage
      environment="city"
      intensity={0.5}
      shadows={{ type: 'accumulative', color: 'black', colorBlend: 2, opacity: 2 }}
      adjustCamera={false}
    >
      {fileType === '.ply' ? (
        <PLYModel url={url} />
      ) : fileType === '.obj' ? (
        <OBJModel url={url} />
      ) : (
        <GLTFModel url={url} />
      )}
    </Stage>
  );
}

function LoadingFallback() {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-xl text-gray-300">Loading...</div>
    </div>
  );
}

export default function ThreeDPage() {
  const [modelUrl, setModelUrl] = useState<string | null>(null);
  const [fileType, setFileType] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleFileUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) return;

    const mainFile = files[0];
    const fileExtension = mainFile.name.toLowerCase().slice(mainFile.name.lastIndexOf('.'));
    
    if (!supportedFormats.includes(fileExtension)) {
      setError('Please upload a .glb, .gltf, .ply, or .obj file');
      return;
    }

    // Create object URLs for all files
    const objectUrl = URL.createObjectURL(mainFile);
    
    // If this is an OBJ file, look for an MTL file
    if (fileExtension === '.obj') {
      const mtlFile = files.find(f => f.name.toLowerCase().endsWith('.mtl'));
      if (mtlFile) {
        const mtlUrl = URL.createObjectURL(mtlFile);
        // Store the MTL URL or handle it as needed
        console.log('MTL file found:', mtlUrl);
      }
    }

    setModelUrl(objectUrl);
    setFileType(fileExtension);
    setError(null);
  }, []);

  // Clean up object URL when component unmounts or when a new file is uploaded
  useEffect(() => {
    return () => {
      if (modelUrl) {
        URL.revokeObjectURL(modelUrl);
      }
    };
  }, [modelUrl]);

  return (
    <div className="bg-black min-h-screen">
      {/* Navigation Bar */}
      <nav className="fixed top-0 left-0 right-0 p-4 z-10 bg-black/50 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <a 
            href="/"
            className="text-white hover:text-gray-300 transition-colors"
          >
            ← Back to Home
          </a>
          
          {/* File Upload Section */}
          <div className="flex items-center gap-4">
            <label className="bg-white/10 hover:bg-white/20 text-white px-4 py-2 rounded-lg cursor-pointer transition-colors">
              Upload 3D Model
              <input
                type="file"
                accept=".glb,.gltf,.ply,.obj,.mtl"
                onChange={handleFileUpload}
                multiple
                className="hidden"
              />
            </label>
            {error && (
              <span className="text-red-400 text-sm">
                {error}
              </span>
            )}
          </div>
        </div>
      </nav>

      {/* 3D Canvas Container */}
      <div className="h-screen w-full">
        <Suspense fallback={<LoadingFallback />}>
          <Canvas
            camera={{ position: [0, 0, 4], fov: 50 }}
            shadows="soft"
            dpr={[1, 2]}
            gl={{ preserveDrawingBuffer: true }}
          >
            <color attach="background" args={['#000000']} />
            <Environment preset="city" />
            {modelUrl ? (
              <Model url={modelUrl} fileType={fileType} />
            ) : (
              <Stage environment="city" intensity={0.5}>
                <Box />
              </Stage>
            )}
            <OrbitControls
              enablePan={true}
              enableZoom={true}
              enableRotate={true}
              minDistance={1}
              maxDistance={10}
              zoomSpeed={1}
              panSpeed={0.5}
              rotateSpeed={0.5}
            />
          </Canvas>
        </Suspense>
      </div>
    </div>
  );
} 