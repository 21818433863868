import React from 'react';
import Home from './pages/Home';
import Desk from './pages/Desk';
import Posts from './pages/Posts';
import Tetris from './pages/Tetris';
import Pacman from './pages/Pacman';
import CV from './pages/CV';
import ThreeDPage from './pages/3d';
import Layout from './components/Layout';

// Create a layout wrapper component
const WithLayout = ({ children }: { children: React.ReactNode }) => (
  <Layout>{children}</Layout>
);

const routes = [
  {
    path: "/",
    element: <WithLayout><Home /></WithLayout>,
  },
  {
    path: "/post",
    element: <WithLayout><Posts /></WithLayout>,
  },
  {
    path: "/contact",
    element: <WithLayout>
      <div>
        <a href="https://www.linkedin.com/in/guvenenes/" target="_blank" rel="noopener noreferrer">
          Open LinkedIn Profile
        </a>
      </div>
    </WithLayout>,
  },
  {
    path: "/desk",
    element: <WithLayout><Desk /></WithLayout>,
  },
  {
    path: "/tetris",
    element: <WithLayout><Tetris /></WithLayout>,
  },
  {
    path: "/pacman",
    element: <WithLayout><Pacman /></WithLayout>,
  },
  {
    path: "/resume",
    element: <WithLayout><CV /></WithLayout>,
  },
  {
    path: "/3d",
    element: <ThreeDPage />, // No layout wrapper for 3D page
  }
];

export default routes;
