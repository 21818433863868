import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const PacmanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const GameInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  max-width: 300px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  min-width: 100px;
  touch-action: manipulation;
  
  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    min-width: 80px;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const Score = styled.h2`
  margin: 0;
  font-size: 16px;
  min-width: 100px;
`;

const StyledCanvas = styled.canvas`
  border: 2px solid #333;
  background-color: #000;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  max-width: 100%;
  height: auto;
`;

const Controls = styled.div`
  display: none;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
`;

const ControlButton = styled.button`
  padding: 20px;
  font-size: 24px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  touch-action: manipulation;
  user-select: none;
  
  &:active {
    background-color: #555;
  }
`;

interface GameState {
  board: number[][];
  context: CanvasRenderingContext2D | null;
  pacman: {
    x: number;
    y: number;
    targetX: number;
    targetY: number;
    direction: number;
    mouthOpen: boolean;
    progress: number;
  };
  ghosts: {
    x: number;
    y: number;
    targetX: number;
    targetY: number;
    direction: number;
    color: string;
    progress: number;
  }[];
  gameOver: boolean;
  paused: boolean;
  lastTime: number;
  moveInterval: number;
}

const Pacman: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [score, setScore] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const gameRef = useRef<GameState>({
    board: [],
    context: null,
    pacman: {
      x: 1,
      y: 1,
      targetX: 1,
      targetY: 1,
      direction: 0,
      mouthOpen: true,
      progress: 1
    },
    ghosts: [
      { x: 13, y: 11, targetX: 13, targetY: 11, direction: 0, color: '#FF0000', progress: 1 },
      { x: 14, y: 11, targetX: 14, targetY: 11, direction: 0, color: '#00FFFF', progress: 1 },
      { x: 15, y: 11, targetX: 15, targetY: 11, direction: 0, color: '#FFB8FF', progress: 1 },
      { x: 16, y: 11, targetX: 16, targetY: 11, direction: 0, color: '#FFB852', progress: 1 }
    ],
    gameOver: false,
    paused: false,
    lastTime: 0,
    moveInterval: 250
  });

  const CELL_SIZE = 20;
  const BOARD_WIDTH = 28;
  const BOARD_HEIGHT = 31;
  const DOT_SIZE = 4;
  const ENERGIZER_SIZE = 8;

  // 0: empty, 1: wall, 2: dot, 3: energizer
  const LEVEL_MAP = [
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [1,2,2,2,2,2,2,2,2,2,2,2,2,1,1,2,2,2,2,2,2,2,2,2,2,2,2,1],
    [1,2,1,1,1,1,2,1,1,1,1,1,2,1,1,2,1,1,1,1,1,2,1,1,1,1,2,1],
    [1,3,1,1,1,1,2,1,1,1,1,1,2,1,1,2,1,1,1,1,1,2,1,1,1,1,3,1],
    [1,2,1,1,1,1,2,1,1,1,1,1,2,1,1,2,1,1,1,1,1,2,1,1,1,1,2,1],
    [1,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,1],
    [1,2,1,1,1,1,2,1,1,2,1,1,1,1,1,1,1,1,2,1,1,2,1,1,1,1,2,1],
    [1,2,1,1,1,1,2,1,1,2,1,1,1,1,1,1,1,1,2,1,1,2,1,1,1,1,2,1],
    [1,2,2,2,2,2,2,1,1,2,2,2,2,1,1,2,2,2,2,1,1,2,2,2,2,2,2,1],
    [1,1,1,1,1,1,2,1,1,1,1,1,0,1,1,0,1,1,1,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,1,1,1,0,1,1,0,1,1,1,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,0,0,0,0,0,0,0,0,0,0,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,0,1,1,1,1,1,1,1,1,0,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,0,1,0,0,0,0,0,0,1,0,1,1,2,1,1,1,1,1,1],
    [0,0,0,0,0,0,2,0,0,0,1,0,0,0,0,0,0,1,0,0,0,2,0,0,0,0,0,0],
    [1,1,1,1,1,1,2,1,1,0,1,0,0,0,0,0,0,1,0,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,0,1,1,1,1,1,1,1,1,0,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,0,0,0,0,0,0,0,0,0,0,1,1,2,1,1,1,1,1,1],
    [1,1,1,1,1,1,2,1,1,0,1,1,1,1,1,1,1,1,0,1,1,2,1,1,1,1,1,1],
    [1,2,2,2,2,2,2,2,2,2,2,2,2,1,1,2,2,2,2,2,2,2,2,2,2,2,2,1],
    [1,2,1,1,1,1,2,1,1,1,1,1,2,1,1,2,1,1,1,1,1,2,1,1,1,1,2,1],
    [1,2,1,1,1,1,2,1,1,1,1,1,2,1,1,2,1,1,1,1,1,2,1,1,1,1,2,1],
    [1,3,2,2,1,1,2,2,2,2,2,2,2,0,0,2,2,2,2,2,2,2,1,1,2,2,3,1],
    [1,1,1,2,1,1,2,1,1,2,1,1,1,1,1,1,1,1,2,1,1,2,1,1,2,1,1,1],
    [1,1,1,2,1,1,2,1,1,2,1,1,1,1,1,1,1,1,2,1,1,2,1,1,2,1,1,1],
    [1,2,2,2,2,2,2,1,1,2,2,2,2,1,1,2,2,2,2,1,1,2,2,2,2,2,2,1],
    [1,2,1,1,1,1,1,1,1,1,1,1,2,1,1,2,1,1,1,1,1,1,1,1,1,1,2,1],
    [1,2,1,1,1,1,1,1,1,1,1,1,2,1,1,2,1,1,1,1,1,1,1,1,1,1,2,1],
    [1,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,2,1],
    [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
  ];

  const createBoard = () => {
    return LEVEL_MAP.map(row => [...row]);
  };

  const draw = () => {
    const { context, board, pacman, ghosts } = gameRef.current;
    if (!context || !canvasRef.current) return;

    // Clear canvas
    context.fillStyle = '#000';
    context.fillRect(0, 0, BOARD_WIDTH * CELL_SIZE, BOARD_HEIGHT * CELL_SIZE);

    // Draw board
    board.forEach((row, y) => {
      row.forEach((cell, x) => {
        if (cell === 1) { // Wall
          context.fillStyle = '#2121ff';
          context.fillRect(x * CELL_SIZE, y * CELL_SIZE, CELL_SIZE, CELL_SIZE);
        } else if (cell === 2) { // Dot
          context.fillStyle = '#ffb8ff';
          context.beginPath();
          context.arc(
            x * CELL_SIZE + CELL_SIZE / 2,
            y * CELL_SIZE + CELL_SIZE / 2,
            DOT_SIZE / 2,
            0,
            Math.PI * 2
          );
          context.fill();
        } else if (cell === 3) { // Energizer
          context.fillStyle = '#ffb8ff';
          context.beginPath();
          context.arc(
            x * CELL_SIZE + CELL_SIZE / 2,
            y * CELL_SIZE + CELL_SIZE / 2,
            ENERGIZER_SIZE / 2,
            0,
            Math.PI * 2
          );
          context.fill();
        }
      });
    });

    // Draw Pacman
    context.fillStyle = '#ffff00';
    context.beginPath();
    const pacmanX = pacman.x * CELL_SIZE + CELL_SIZE / 2;
    const pacmanY = pacman.y * CELL_SIZE + CELL_SIZE / 2;
    const mouthAngle = pacman.mouthOpen ? 0.2 * Math.PI : 0;
    const startAngle = pacman.direction * Math.PI / 2 + mouthAngle;
    const endAngle = pacman.direction * Math.PI / 2 + (2 * Math.PI - mouthAngle);
    context.arc(pacmanX, pacmanY, CELL_SIZE / 2 - 2, startAngle, endAngle);
    context.lineTo(pacmanX, pacmanY);
    context.fill();

    // Draw Ghosts
    ghosts.forEach(ghost => {
      context.fillStyle = ghost.color;
      context.beginPath();
      const ghostX = ghost.x * CELL_SIZE + CELL_SIZE / 2;
      const ghostY = ghost.y * CELL_SIZE + CELL_SIZE / 2;
      
      // Ghost body
      context.arc(ghostX, ghostY - 2, CELL_SIZE / 2 - 2, Math.PI, 0, false);
      context.lineTo(ghostX + CELL_SIZE / 2 - 2, ghostY + CELL_SIZE / 2 - 2);
      
      // Ghost skirt
      for (let i = 0; i < 3; i++) {
        const curve = CELL_SIZE / 6;
        context.quadraticCurveTo(
          ghostX + CELL_SIZE / 2 - 2 - curve * (2 - i),
          ghostY + CELL_SIZE / 2,
          ghostX + CELL_SIZE / 2 - 2 - curve * (3 - i),
          ghostY + CELL_SIZE / 2 - 2
        );
      }
      
      context.lineTo(ghostX - CELL_SIZE / 2 + 2, ghostY + CELL_SIZE / 2 - 2);
      context.lineTo(ghostX - CELL_SIZE / 2 + 2, ghostY - 2);
      context.fill();

      // Ghost eyes
      context.fillStyle = 'white';
      context.beginPath();
      context.arc(ghostX - 4, ghostY - 4, 3, 0, Math.PI * 2);
      context.arc(ghostX + 4, ghostY - 4, 3, 0, Math.PI * 2);
      context.fill();

      context.fillStyle = 'blue';
      context.beginPath();
      context.arc(ghostX - 4, ghostY - 4, 1.5, 0, Math.PI * 2);
      context.arc(ghostX + 4, ghostY - 4, 1.5, 0, Math.PI * 2);
      context.fill();
    });
  };

  const moveGhosts = () => {
    const { board, ghosts, pacman } = gameRef.current;
    
    ghosts.forEach(ghost => {
      // Only move if we've reached the target position
      if (ghost.progress >= 1) {
        const possibleDirections: number[] = [];
        const directions = [
          { dx: 1, dy: 0 },  // right
          { dx: 0, dy: 1 },  // down
          { dx: -1, dy: 0 }, // left
          { dx: 0, dy: -1 }  // up
        ];

        directions.forEach((dir, index) => {
          const newX = Math.round(ghost.x) + dir.dx;
          const newY = Math.round(ghost.y) + dir.dy;
          if (board[newY] && board[newY][newX] !== 1) {
            possibleDirections.push(index);
          }
        });

        if (possibleDirections.length > 0) {
          const dx = pacman.x - ghost.x;
          const dy = pacman.y - ghost.y;
          let bestDirection = possibleDirections[0];
          
          if (Math.abs(dx) > Math.abs(dy)) {
            if (dx > 0 && possibleDirections.includes(0)) bestDirection = 0;
            else if (dx < 0 && possibleDirections.includes(2)) bestDirection = 2;
          } else {
            if (dy > 0 && possibleDirections.includes(1)) bestDirection = 1;
            else if (dy < 0 && possibleDirections.includes(3)) bestDirection = 3;
          }

          ghost.direction = bestDirection;
          const dir = directions[bestDirection];
          ghost.targetX = Math.round(ghost.x) + dir.dx;
          ghost.targetY = Math.round(ghost.y) + dir.dy;
          ghost.progress = 0;
        }
      }
    });
  };

  const updatePositions = (deltaTime: number) => {
    const { ghosts, pacman } = gameRef.current;
    const speed = deltaTime / gameRef.current.moveInterval;
    
    // Update Pacman position
    if (pacman.progress < 1) {
      pacman.progress = Math.min(pacman.progress + speed, 1);
      pacman.x = pacman.x + (pacman.targetX - pacman.x) * speed;
      pacman.y = pacman.y + (pacman.targetY - pacman.y) * speed;
    }

    // Update Ghost positions
    ghosts.forEach(ghost => {
      if (ghost.progress < 1) {
        ghost.progress = Math.min(ghost.progress + speed, 1);
        ghost.x = ghost.x + (ghost.targetX - ghost.x) * speed;
        ghost.y = ghost.y + (ghost.targetY - ghost.y) * speed;
      }
    });
  };

  const checkCollision = () => {
    const { ghosts, pacman } = gameRef.current;
    const collisionThreshold = 0.5;
    return ghosts.some(ghost => {
      const dx = ghost.x - pacman.x;
      const dy = ghost.y - pacman.y;
      return Math.sqrt(dx * dx + dy * dy) < collisionThreshold;
    });
  };

  const update = (time = 0) => {
    const game = gameRef.current;
    if (game.gameOver || game.paused) return;

    const deltaTime = time - game.lastTime;
    
    if (!game.lastTime || deltaTime > game.moveInterval) {
      // Move ghosts
      moveGhosts();

      // Check collision with ghosts
      if (checkCollision()) {
        game.gameOver = true;
        return;
      }

      // Update Pacman mouth animation
      game.pacman.mouthOpen = !game.pacman.mouthOpen;

      game.lastTime = time;
    }

    // Update all positions for smooth movement
    updatePositions(deltaTime);

    draw();
    requestAnimationFrame(update);
  };

  const move = (dir: number) => {
    const { board, pacman } = gameRef.current;
    
    // Only allow movement if we've reached the target position
    if (pacman.progress < 1) return;

    const directions = [
      { dx: 1, dy: 0 },  // right
      { dx: 0, dy: 1 },  // down
      { dx: -1, dy: 0 }, // left
      { dx: 0, dy: -1 }  // up
    ];

    pacman.direction = dir;
    const newX = Math.round(pacman.x) + directions[dir].dx;
    const newY = Math.round(pacman.y) + directions[dir].dy;

    // Check if the new position is valid
    if (board[newY] && board[newY][newX] !== 1) {
      pacman.targetX = newX;
      pacman.targetY = newY;
      pacman.progress = 0;

      // Collect dots (using rounded current position)
      const currentX = Math.round(pacman.x);
      const currentY = Math.round(pacman.y);
      if (board[currentY][currentX] === 2) {
        board[currentY][currentX] = 0;
        setScore(prev => prev + 10);
      } else if (board[currentY][currentX] === 3) {
        board[currentY][currentX] = 0;
        setScore(prev => prev + 50);
        // TODO: Make ghosts vulnerable
      }
    }

    // Wrap around
    if (newY === 14) {
      if (newX < 0) {
        pacman.targetX = BOARD_WIDTH - 1;
        pacman.x = BOARD_WIDTH - 1;
      }
      if (newX >= BOARD_WIDTH) {
        pacman.targetX = 0;
        pacman.x = 0;
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const game = gameRef.current;
    if (game.gameOver) return;

    switch(event.keyCode) {
      case 37: // Left
        move(2);
        break;
      case 38: // Up
        move(3);
        break;
      case 39: // Right
        move(0);
        break;
      case 40: // Down
        move(1);
        break;
      default:
        return;
    }
    draw();
  };

  const handleStartPause = () => {
    const game = gameRef.current;
    if (game.gameOver) {
      game.board = createBoard();
      game.pacman = { 
        x: 1, 
        y: 1, 
        targetX: 1, 
        targetY: 1, 
        direction: 0, 
        mouthOpen: true, 
        progress: 1 
      };
      game.ghosts = [
        { x: 13, y: 11, targetX: 13, targetY: 11, direction: 0, color: '#FF0000', progress: 1 },
        { x: 14, y: 11, targetX: 14, targetY: 11, direction: 0, color: '#00FFFF', progress: 1 },
        { x: 15, y: 11, targetX: 15, targetY: 11, direction: 0, color: '#FFB8FF', progress: 1 },
        { x: 16, y: 11, targetX: 16, targetY: 11, direction: 0, color: '#FFB852', progress: 1 }
      ];
      game.gameOver = false;
      game.paused = false;
      setScore(0);
      requestAnimationFrame(update);
    } else {
      game.paused = !game.paused;
      if (!game.paused) {
        game.lastTime = 0;
        requestAnimationFrame(update);
      }
    }
  };

  const handleTouchControl = (action: 'left' | 'right' | 'down' | 'up') => {
    const game = gameRef.current;
    if (game.gameOver || game.paused) return;

    switch (action) {
      case 'left':
        move(2);
        break;
      case 'right':
        move(0);
        break;
      case 'down':
        move(1);
        break;
      case 'up':
        move(3);
        break;
    }
    draw();
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (!context) return;

    // Set canvas size based on device
    const displayWidth = Math.min(window.innerWidth - 40, 600);
    const displayHeight = (displayWidth / BOARD_WIDTH) * BOARD_HEIGHT;
    
    canvas.width = BOARD_WIDTH * CELL_SIZE;
    canvas.height = BOARD_HEIGHT * CELL_SIZE;
    
    canvas.style.width = `${displayWidth}px`;
    canvas.style.height = `${displayHeight}px`;
    
    context.scale(1, 1);

    gameRef.current.context = context;
    gameRef.current.board = createBoard();
    
    window.addEventListener('keydown', handleKeyDown);
    requestAnimationFrame(update);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="flex flex-col items-center p-2.5 gap-2.5 w-full max-w-3xl mx-auto">
      <div className="flex items-center justify-center gap-4 w-full max-w-[300px]">
        <h2 className="m-0 text-base min-w-[100px]">Score: {score}</h2>
        <button 
          onClick={handleStartPause}
          className="px-4 py-2 text-base cursor-pointer bg-green-500 text-white border-none rounded min-w-[100px] hover:bg-green-600 transition-colors duration-200 md:min-w-[80px] md:px-3 md:py-2 md:text-sm"
        >
          {gameRef.current.gameOver ? 'Start' : gameRef.current.paused ? 'Resume' : 'Pause'}
        </button>
      </div>
      
      <StyledCanvas 
        ref={canvasRef} 
        className="border-2 border-gray-700 shadow-lg"
      />
      
      {isMobile && (
        <div className="hidden w-full max-w-[400px] mt-5 md:grid grid-cols-3 gap-2.5">
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800"
            onTouchStart={() => handleTouchControl('left')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            ←
          </ControlButton>
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800"
            onTouchStart={() => handleTouchControl('up')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            ↑
          </ControlButton>
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800"
            onTouchStart={() => handleTouchControl('right')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            →
          </ControlButton>
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800 col-span-3"
            onTouchStart={() => handleTouchControl('down')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            ↓
          </ControlButton>
        </div>
      )}
      <p className="text-center text-sm text-gray-500">
        Use arrow keys to control Pacman. Collect all dots while avoiding ghosts!
      </p>
    </div>
  );
};

export default Pacman; 