import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const TetrisContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const GameInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  max-width: 300px;
`;

const StyledButton = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  min-width: 100px;
  touch-action: manipulation;
  
  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    min-width: 80px;
    padding: 8px 12px;
    font-size: 14px;
  }
`;

const Score = styled.h2`
  margin: 0;
  font-size: 16px;
  min-width: 100px;
`;

const StyledCanvas = styled.canvas`
  border: 2px solid #333;
  background-color: #000;
  box-shadow: 0 0 20px rgba(0,0,0,0.1);
  max-width: 100%;
  height: auto;
`;

const Controls = styled.div`
  display: none;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
  
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
`;

const ControlButton = styled.button`
  padding: 20px;
  font-size: 24px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  touch-action: manipulation;
  user-select: none;
  
  &:active {
    background-color: #555;
  }
`;

interface GameState {
  board: (string | number)[][] | null;
  context: CanvasRenderingContext2D | null;
  piece: {
    shape: number[][];
    pos: { x: number; y: number };
    color: string;
  } | null;
  gameOver: boolean;
  paused: boolean;
  dropCounter: number;
  lastTime: number;
  dropInterval: number;
}

const Tetris: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [score, setScore] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const gameRef = useRef<GameState>({
    board: null,
    context: null,
    piece: null,
    gameOver: false,
    paused: false,
    dropCounter: 0,
    lastTime: 0,
    dropInterval: 1000,
  });

  const BLOCK_SIZE = 20;
  const BOARD_WIDTH = 12;
  const BOARD_HEIGHT = 20;

  const SHAPES = [
    [[1, 1, 1, 1]],                // I
    [[1, 1], [1, 1]],             // O
    [[1, 1, 1], [0, 1, 0]],       // T
    [[1, 1, 1], [1, 0, 0]],       // L
    [[1, 1, 1], [0, 0, 1]],       // J
    [[1, 1, 0], [0, 1, 1]],       // S
    [[0, 1, 1], [1, 1, 0]]        // Z
  ];

  const COLORS = [
    '#ff0000', '#00ff00', '#0000ff', 
    '#ffff00', '#ff00ff', '#00ffff', '#ffa500'
  ];

  const createBoard = () => {
    return Array(BOARD_HEIGHT).fill(null).map(() => Array(BOARD_WIDTH).fill(0));
  };

  const createPiece = () => {
    const shape = SHAPES[Math.floor(Math.random() * SHAPES.length)];
    const color = COLORS[Math.floor(Math.random() * COLORS.length)];
    return {
      shape,
      pos: {x: Math.floor(BOARD_WIDTH/2 - shape[0].length/2), y: 0},
      color
    };
  };

  const draw = () => {
    const { context, board, piece } = gameRef.current;
    if (!context || !canvasRef.current || !board) return;

    // Clear canvas with black background
    context.fillStyle = '#000';
    context.fillRect(0, 0, BOARD_WIDTH, BOARD_HEIGHT);
    
    // Draw board with slightly smaller blocks for spacing
    board.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value) {
          context.fillStyle = value.toString();
          context.fillRect(x + 0.05, y + 0.05, 0.9, 0.9);
          // Add thinner borders to blocks
          context.strokeStyle = '#fff';
          context.lineWidth = 0.05;
          context.strokeRect(x + 0.05, y + 0.05, 0.9, 0.9);
        }
      });
    });

    // Draw current piece with slightly smaller blocks
    if (piece) {
      piece.shape.forEach((row, y) => {
        row.forEach((value, x) => {
          if (value) {
            context.fillStyle = piece.color;
            context.fillRect(x + piece.pos.x + 0.05, y + piece.pos.y + 0.05, 0.9, 0.9);
            // Add thinner borders to piece blocks
            context.strokeStyle = '#fff';
            context.lineWidth = 0.05;
            context.strokeRect(x + piece.pos.x + 0.05, y + piece.pos.y + 0.05, 0.9, 0.9);
          }
        });
      });
    }
  };

  const collision = () => {
    const { board, piece } = gameRef.current;
    if (!board || !piece) return false;

    const [m, o] = [piece.shape, piece.pos];
    for (let y = 0; y < m.length; y++) {
      for (let x = 0; x < m[y].length; x++) {
        if (m[y][x] !== 0 &&
            (board[y + o.y] &&
            board[y + o.y][x + o.x]) !== 0) {
          return true;
        }
      }
    }
    return false;
  };

  const merge = () => {
    const { board, piece } = gameRef.current;
    if (!board || !piece) return;

    piece.shape.forEach((row, y) => {
      row.forEach((value, x) => {
        if (value) {
          board[y + piece.pos.y][x + piece.pos.x] = piece.color;
        }
      });
    });
  };

  const rotate = () => {
    const { piece } = gameRef.current;
    if (!piece) return;

    const shape = piece.shape;
    const newShape: number[][] = [];
    for (let i = 0; i < shape[0].length; i++) {
      newShape.push([]);
      for (let j = shape.length - 1; j >= 0; j--) {
        newShape[i].push(shape[j][i]);
      }
    }
    const oldShape = piece.shape;
    piece.shape = newShape;
    if (collision()) {
      piece.shape = oldShape;
    }
  };

  const clearLines = () => {
    const { board } = gameRef.current;
    if (!board) return;

    let linesCleared = 0;
    outer: for (let y = board.length - 1; y >= 0; y--) {
      for (let x = 0; x < board[y].length; x++) {
        if (board[y][x] === 0) {
          continue outer;
        }
      }
      const row = board.splice(y, 1)[0].fill(0);
      board.unshift(row);
      linesCleared++;
      y++;
    }
    if (linesCleared > 0) {
      setScore(prev => prev + linesCleared * 100);
    }
  };

  const update = (time = 0) => {
    const game = gameRef.current;
    if (game.gameOver || game.paused || !game.piece) return;

    const deltaTime = time - game.lastTime;
    game.lastTime = time;
    game.dropCounter += deltaTime;

    if (game.dropCounter > game.dropInterval) {
      game.piece.pos.y++;
      if (collision()) {
        game.piece.pos.y--;
        merge();
        clearLines();
        game.piece = createPiece();
        if (collision()) {
          game.gameOver = true;
          return;
        }
      }
      game.dropCounter = 0;
    }

    draw();
    requestAnimationFrame(update);
  };

  const move = (dir: number) => {
    const { piece } = gameRef.current;
    if (!piece) return;

    piece.pos.x += dir;
    if (collision()) {
      piece.pos.x -= dir;
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const game = gameRef.current;
    if (game.gameOver || !game.piece) return;

    switch(event.keyCode) {
      case 37: // Left
        move(-1);
        break;
      case 39: // Right
        move(1);
        break;
      case 40: // Down
        game.piece.pos.y++;
        if (collision()) {
          game.piece.pos.y--;
          merge();
          clearLines();
          game.piece = createPiece();
          if (collision()) {
            game.gameOver = true;
          }
        }
        game.dropCounter = 0;
        break;
      case 38: // Up
        rotate();
        break;
      default:
        return;
    }
    draw();
  };

  const handleStartPause = () => {
    const game = gameRef.current;
    if (game.gameOver) {
      game.board = createBoard();
      game.piece = createPiece();
      game.gameOver = false;
      game.paused = false;
      setScore(0);
      requestAnimationFrame(update);
    } else {
      game.paused = !game.paused;
      if (!game.paused) {
        game.lastTime = 0;
        requestAnimationFrame(update);
      }
    }
  };

  const handleTouchControl = (action: 'left' | 'right' | 'down' | 'rotate') => {
    const game = gameRef.current;
    if (game.gameOver || game.paused) return;

    switch (action) {
      case 'left':
        move(-1);
        break;
      case 'right':
        move(1);
        break;
      case 'down':
        game.dropInterval = 50;
        break;
      case 'rotate':
        rotate();
        break;
    }
    draw();
  };

  const handleTouchEnd = () => {
    const game = gameRef.current;
    game.dropInterval = 1000;
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    if (!canvasRef.current) return;
    
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    if (!context) return;

    // Set canvas size based on device
    const displayWidth = Math.min(window.innerWidth - 40, 300);
    const displayHeight = (displayWidth / BOARD_WIDTH) * BOARD_HEIGHT;
    
    // Set the actual canvas dimensions smaller (10 pixels per block instead of 20)
    canvas.width = BOARD_WIDTH * 10;
    canvas.height = BOARD_HEIGHT * 10;
    
    // Set the display size through CSS
    canvas.style.width = `${displayWidth}px`;
    canvas.style.height = `${displayHeight}px`;
    
    // Scale the context to match our smaller block size
    context.scale(10, 10);
    context.imageSmoothingEnabled = false;

    gameRef.current.context = context;
    gameRef.current.board = createBoard();
    gameRef.current.piece = createPiece();
    
    window.addEventListener('keydown', handleKeyDown);
    requestAnimationFrame(update);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="flex flex-col items-center p-2.5 gap-2.5 w-full max-w-3xl mx-auto">
      <div className="flex items-center justify-center gap-4 w-full max-w-[300px]">
        <h2 className="m-0 text-base min-w-[100px]">Score: {score}</h2>
        <button 
          onClick={handleStartPause}
          className="px-4 py-2 text-base cursor-pointer bg-green-500 text-white border-none rounded min-w-[100px] hover:bg-green-600 transition-colors duration-200 md:min-w-[80px] md:px-3 md:py-2 md:text-sm"
        >
          {gameRef.current.gameOver ? 'Start' : gameRef.current.paused ? 'Resume' : 'Pause'}
        </button>
      </div>
      
      <StyledCanvas 
        ref={canvasRef} 
        className="border-2 border-gray-700 shadow-lg"
      />
      
      {isMobile ? (
        <div className="hidden w-full max-w-[400px] mt-5 md:grid grid-cols-3 gap-2.5">
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800"
            onTouchStart={() => handleTouchControl('left')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            ←
          </ControlButton>
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800"
            onTouchStart={() => handleTouchControl('down')}
            onTouchEnd={(e) => {
              e.preventDefault();
              handleTouchEnd();
            }}
          >
            ↓
          </ControlButton>
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800"
            onTouchStart={() => handleTouchControl('right')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            →
          </ControlButton>
          <ControlButton
            className="p-5 text-2xl bg-gray-700 text-white border-none rounded hover:bg-gray-600 active:bg-gray-800 col-span-3"
            onTouchStart={() => handleTouchControl('rotate')}
            onTouchEnd={(e) => e.preventDefault()}
          >
            Rotate
          </ControlButton>
        </div>
      ) : null}
      <p className="text-center text-sm text-gray-500">
        I made this game when testing cursor then i said why not deploy it.
      </p>
    </div>
  );
};

export default Tetris; 